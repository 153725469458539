export default defineNuxtRouteMiddleware((to, from) => {
  const sn = to.query.sn
  const utm_source = to.query.utm_source
  if (to.name === 'services-warranty-form') {
    if (sn && !utm_source) {
      return {
        path: '/services/warranty/form',
        query: {
          sn: sn,
          utm_source: 'warranty',
          utm_medium: 'qrcode',
          utm_campaign: 'sn'
        }
      }
    }
  }
})
